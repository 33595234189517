export const NAVIGATION_PATH = {
  LOGIN: 'autentificare',
  AGENCIES: 'agentii',
  USERS: 'utilizatori',
  BET_REGISTER: 'registru',
  TRANSACTIONS: 'tranzactii',
  DETAILS: 'detalii',
  CREATE: 'adauga',
  CHANGE_PASSWORD: 'schimbare-parola',
  RESET_PASSWORD: 'authentication/reset-password',
  DOCUMENT: 'documente',
} as const;
